body, html {
	font-family: Roboto, sans-serif;
}

strong {
	font-weight: 600 !important;
}

h1, h2, h3, h4, h5, h6 {
	font-family: Roboto, sans-serif;
}

p, a, li, span, div {
	font-family: Roboto, sans-serif;
}

.floating-ivw-logo {
	display: none;
}

.news-slider .slick-dots {
	@include media-breakpoint-up(m) {
		bottom: calc(20.2rem - 40px);
	}

}

.news-slider .news-teaser__content {
	@include media-breakpoint-up(m) {
		height: 20.2rem;
	}
}

.nav .nav-desktop__lower {
	background: #DD0B2F;
}

.nav .nav-mobile__upper {
	background: #DD0B2F;
}

.nav-wrapper {
	background: #DD0B2F;
}

.breaking-news {
	.btn {
		background-color: #DD0B2F;

	}
}

.tabs--vertical .tab i {
	background-color: #DD0B2F;
}

.header__search {
	&__form, .search__submit, .search__field--focus, .search__field--active {
		background: #DD0B2F !important;
	}
}

.nav .badge {
	line-height: 3.1 !important;
    font-size: .5rem !important;
}

.pswp__top-bar {
	background-color: #DD0B2F !important;
}


.timeline__time {
    font-size: 1.6rem;
}

.timeline__date {
    font-size: .66rem;
    font-weight: 500;
}

@include media-breakpoint-up(m) {
	.timeline__datetime::after {
		bottom: 32%;
	}
}


// nav social media links
.nav-social-media-links {
	padding-left: rem(14px);

	@include media-breakpoint-up(xl) {
		display: flex !important;
	}

	a {
		margin-left: 1.125rem;
		position: relative;
		overflow: hidden;
		display: block;
		height: 48px;
		width: 48px;

		.mdi {
			justify-content: center;
			align-items: center;
			display: flex;
			color: white;
			line-height: 2.5rem;
			font-size: 2.5rem;
			height: 48px;
			width: 48px;
		}
	}
}
